.reference-images {
  margin: 20px 0px 40px 0px;
  display: flex;
  flex-wrap: wrap;
}

.reference-images > div {
  display: flex;
  flex-wrap: nowrap;
  width: 318px;
}

.reference-images > div > div {
  width: 159px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.special-right-triangles-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.special-right-triangles-container > div {
  display: flex;
  flex-wrap: nowrap;
  width: 318px !important;
}

.special-right-triangles-container > div > div {
  display: flex;
  flex-wrap: nowrap;
  width: 148px !important;
  justify-content: center;
}

.special-right-triangles-container > div > div:last-child {
  margin-bottom: 14px;
}

#specialRightTrianglesText {
  font-family: NotoSerif;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 12px;
  height: 20px;
}

.reference-text {
  margin: 0 50px 25px 42px;
}

.reference-text p {
  margin-top: 10px;
}
