ol, ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  border-top: 1px solid var(--gray-color);
}

li:first-child {
  border-top: none;
}
