@import "./colors";

button, .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: var(--font-size);
    color: var(--black-color);
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 6px;
}

/* Button color declarations. */
@each $color in map-keys($colors) {
    .btn-#{$color} {
        color: #FFFFFF;
        background-color: map-get($colors, $color);
        border-color: map-get($colors, $color);
    }
}

.btn-menu {
    background: url("../hamburger_menu.svg") no-repeat center;
    border: none;
    width: 20px;
    height: 18px;
    border-radius: 0;
}

.btn-link {
    color: var(--primary-color);
    text-decoration: none;
}
