$colors: (
        primary: var(--primary-color),
        secondary: var(--secondary-color),
        info: var(--info-color),
        gray: var(--gray-color),
        success: var(--success-color),
        warning: var(--warning-color),
        danger: var(--danger-color),
        black: var(--black-color),
        light: var(--light-color),
        white: var(--white-color),
);
