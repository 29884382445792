@import "./colors";
@import "./button";
@import "./text";
@import "./layout";
@import "./background";
@import "./list";
@import "./table";

@import url(http://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --primary-color: #707BFC;
  --secondary-color: #6484FC;
  --info-color: #2CABF5;
  --gray-color: #D5CDCDFF;
  --success-color: #2F9D27;
  --warning-color: #E0B94F;
  --danger-color: #FF3232;
  --black-color: #050505;
  --light-color: #F0F0F0;
  --white-color: #FFFFFF;
  --font-size: 16px;
  --padding-size: 16px;
  --margin-size: 16px;
}

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: black;
  border: 1px solid black;
  border-radius: 151px;
}

