table {
    border: 1px solid black;
    border-collapse: collapse;
    max-width: 100%;
    width: 100%;
}

tr {
    border-bottom: 1px solid black;
}

th, td {
    border-right: 1px solid black;
    padding: 15px;
    text-align: center;
}

th:last-child, td:last-child {
    border-right: none;
}

svg {
    max-width: 100%;
}

p {
    margin: 0;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
}
