$size: 16px;
$axis: '' '', t -top, l -left, r -right, b -bottom, x -left -right, y -top -bottom;
$multiples: 0, 0.25, 0.5, 1, 1.5, 3;

.wrapper {}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1156px;
  flex-shrink: 0;
  overflow-y: auto;
}

.page-content {
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 42px 58px;
  margin: 18px 38px;
}

div {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.divider {
  height: 1px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// Padding
@each $k in $axis {
  @for $j from 1 through length($multiples) {
    .p#{nth($k, 1)}-#{$j - 1} {
      @for $i from 2 through length($k) {
        padding#{nth($k, $i)}: $size * nth($multiples, $j);
      }
    }
  }
}

// Margin
@each $k in $axis {
  @for $j from 1 through length($multiples) {
    .m#{nth($k, 1)}-#{$j - 1} {
      @for $i from 2 through length($k) {
        margin#{nth($k, $i)}: $size * nth($multiples, $j);
      }
    }
  }
}

// Gap
@for $i from 1 through length($multiples) {
  .gap-#{$i - 1} {
    gap: $size * nth($multiples, $i);
  }
}
