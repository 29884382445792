@import "./colors";

@each $color in map-keys($colors) {
    .bg-#{$color} {
        background-color: map-get($colors, $color);
    }
}

.bg-main {
    background: url("../background.svg") no-repeat;
    background-size: cover;
}
