.table {
  border-collapse: collapse;
  border: none;

  th, td {
    border: none;
  }

  tr {
    cursor: pointer;
    border-bottom-color: var(--gray-color);
  }

  tbody > tr:hover {
    background: var(--info-color);
    color: var(--light-color);
  }
}
