@import "./colors";

/* Text color declarations. */
@each $color in map-keys($colors) {
    .text-#{$color} {
        color: map-get($colors, $color);
    }
}

.text-left {
    text-align: start;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: end;
}

.font-bold {
    font-weight: 700;
}

.font-500 {
    font-weight: 500;
}

.font-thin {
    font-weight: 300;
}

.font-italic {
    font-style: italic;
}

h1 {
    font-size: 48px;
    line-height: 40px;
    margin: 0;
}

h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0;
}

h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
}

h4 {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
}

h5 {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
